<template>
  <div class="page-sidebar ps ps--active-y">
    <ul class="list-unstyled accordion-menu">
      <li class="sidebar-title">{{ $t("MENU.main") }}</li>

      <li class="active-page">
        <router-link @click="$emit('menu-click')" class="nav-link" to="/">
          <vue-feather type="monitor" />{{ $t("MENU.dashboard") }}
        </router-link>
      </li>
      <li v-for="item in main" :key="item.link">
        <side-bar-item @menu-click="$emit('menu-click')" :item="item" />
      </li>

      <li class="sidebar-title">{{ $t("MENU.management") }}</li>
      <li v-for="item in managementItems" :key="item.link">
        <side-bar-item @menu-click="$emit('menu-click')" :item="item" />
      </li>

      <li class="sidebar-title">{{ $t("MENU.finance") }}</li>
      <li v-for="item in finances" :key="item.link">
        <side-bar-item @menu-click="$emit('menu-click')" :item="item" />
      </li>

      <li class="sidebar-title">{{ $t("MENU.personalization") }}</li>
      <li>
        <side-bar-item
          @menu-click="$emit('menu-click')"
          :item="{
            link: '/settings',
            icon: 'sliders',
            title: this.$t('MENU.settings'),
          }"
        />
        <side-bar-item
          v-if="1 === 2"
          @menu-click="$emit('menu-click')"
          :item="{
            link: '/knowledge-base',
            icon: 'life-buoy',
            title: 'База знаний',
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SideBarItem from "@/components/SideBarItem";
import { mapGetters } from "vuex";
export default {
  name: "left-sidebar",
  emits: ["menu-click"],
  components: {
    SideBarItem,
  },
  data() {
    return {
      showSubmenu: false,
      showSubmenuSelected: false,
      main: [
        {
          link: "/message",
          icon: "message-square",
          title: this.$t("MENU.message"),
        },
        {
          link: "/campaign",
          icon: "activity",
          title: this.$t("MENU.campaign"),
        },
      ],
      managements: [
        {
          link: "/client",
          icon: "users",
          title: this.$t("MENU.clients"),
          admin: true,
          client: false,
        },
        {
          link: "/address-book",
          icon: "book",
          title: this.$t("MENU.address_books"),
          admin: true,
          client: true,
        },
        {
          link: "/template",
          icon: "bookmark",
          title: this.$t("MENU.templates"),
          admin: true,
          client: true,
        },
        {
          link: "/sender-alias",
          icon: "send",
          title: this.$t("MENU.sender_alias"),
          admin: true,
          client: true,
        },
      ],
      finances: [
        {
          link: "/invoice",
          icon: "clipboard",
          title: this.$t("MENU.invoices"),
        },
        {
          link: "/payment",
          icon: "dollar-sign",
          title: this.$t("MENU.payments"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    managementItems() {
      if (this.isAdminRole) {
        return this.managements.filter((item) => item.admin === true);
      }

      return this.managements.filter((item) => item.client === true);
    },
    isAdminRole() {
      return this.user && this.user.user.roles[0] === "ROLE_SUPER_ADMIN";
    },
  },
  created() {
    if (this.isAdminRole) {
      this.managements.splice(1, 2);
    }
  },
};
</script>

<style scoped>
.dropdown-icon {
  text-align: right !important;
  margin-right: 0 !important;
}
.page-sidebar .accordion-menu > li > a > i.dropdown-icon {
  width: initial;
  height: initial;
}
</style>
