<template>
  <button class="btn btn-success m-b-xs" type="button" :disabled="processing">
    <template v-if="processing">
      <span class="spinner-border spinner-border-sm"></span>
    </template>
    <template v-else>{{ $t("COMMON.save") }}</template>
  </button>
</template>

<script>
export default {
  name: "rockSubmitButton",
  props: ["processing"],
};
</script>
