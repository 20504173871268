<template>
  <li class="nav-item dropdown">
    <a
      class="nav-link"
      href="#"
      id="languageDropDown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $i18n.locale }}
    </a>

    <div
      class="dropdown-menu dropdown-menu-end profile-drop-menu"
      aria-labelledby="profileDropDown"
    >
      <a class="dropdown-item" href="#" @click="switchLang('ru')"> русский </a>
      <a class="dropdown-item" href="#" @click="switchLang('ro')"> română </a>
      <a class="dropdown-item" href="#" @click="switchLang('en')"> english </a>
    </div>
  </li>
</template>

<script>
export default {
  name: "lang-switch",
  methods: {
    switchLang(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
      location.reload();
    },
  },
};
</script>
